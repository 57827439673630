import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from 'vue-lang-router';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta'
import { VueMaskDirective } from 'v-mask';
import directives from "@/directives";
import './registerServiceWorker'

const VueScrollTo = require('vue-scrollto');
const metaManager = createMetaManager()
const app = createApp(App);

const vMaskV2 = VueMaskDirective;
const vMaskV3 = {
    beforeMount: vMaskV2.bind,
    updated: vMaskV2.componentUpdated,
    unmounted: vMaskV2.unbind
};

directives.forEach(directive => {
    app.directive(directive.name, directive);
});

app.config.globalProperties.valuta = 'грн';
app
    .directive('mask', vMaskV3)
    .use(router)
    .use(metaManager, { refreshOnceOnNavigation: true })
    .use(metaPlugin)
    .use(store)
    .use(i18n)
    .use(VueScrollTo, {
        duration: 1000,
        easing: "ease",
        offset: -105,
        y: true
    })
    .mount('#app')