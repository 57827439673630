export const cartModule = {

    state: {
        cart: [],
    },

    getters: {
        getCart: state => {
            return state.cart
        }
    },

    mutations: {
        addToCart(state, item) {
            state.cart.push(item);
        },
        addMoreToCart(state, item) {
            item.forEach((element) => {
                state.cart.push(element);
            });
        },
        dropCart(state, item) {

            for (let i = 0; i < state.cart.length; i++) {
                if (state.cart[i].id === item) {
                    state.cart.splice(i, 1);
                    break;
                }
            }
        }
    },

    computed: {
        totalPrice() {
            let total = 0;

            for (let item of this.$store.state.cart) {
                total += item.totalPrice;
            }

            return total.toFixed(2);
        }
    },

    namespaced: true,

    methods: {
        // clear(arr, attr, value){
        //   var i = arr.length;
        //   while(i--){
        //     if( arr[i]
        //       && arr[i].hasOwnProperty(attr)
        //       && (arguments.length > 2 && arr[i][attr] === value ) ){
        //
        //       arr.splice(i,1);
        //
        //     }
        //   }
        //   return arr;
        // }
    }
};