import {createStore} from 'vuex'
import {langModule} from "@/store/langModule";
import {cartModule} from "@/store/cartModule";
import createPersistedState from "vuex-persistedstate";

export default createStore({
    plugins: [createPersistedState()],
    modules: {
      lang: langModule,
      cart: cartModule,
    }
})
