export default {
  ru: {
    name: 'Ру',
    load: () => { return import('./ru.json'); },
  },
	uk: {
		name: 'Укр',
		load: () => { return import('./uk.json'); },
	},
};
