export const langModule = {

    state: () => ({
        lang: localStorage.getItem('VueAppLanguage') || 'uk'
    }),

    getters: {
      lang(state) {
          return state.lang
      }
    },

    mutations: {
      changeLangToRu (state) {
       state.lang = 'ru'
      },
      changeLangToUk (state) {
        state.lang = 'uk'
      },
    },

    actions: {},

    // namespaced: true
};
