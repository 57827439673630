import { createWebHistory } from 'vue-router';
import VueBodyClass from 'vue-body-class';
import translations from '../locales'
// import localizedURLs from '../lang/localized-urls'
import { createLangRouter } from 'vue-lang-router'

//Views
const Home = () => import('../views/Home.vue');
const BatchSolutions = () => import('../views/BatchSolutions.vue');

const IpTelephony = () => import('../views/IpTelephony/Index.vue');
const IpTelephonyChildren = () => import('../views/IpTelephony/Children.vue');
const IpTelephonyParent = () => import('../views/IpTelephony/Parent.vue');

const Set = () => import('../views/Set/Index.vue');
const SetChildren = () => import('../views/Set/Children.vue');
const SetParent = () => import('../views/Set/Parent.vue');

const Blog = () => import('../views/Blog/Index.vue');
const BlogIndex = () => import('../views/Blog/Blog.vue');
const BlogNews = () => import('../views/Blog/News.vue');
const BlogInfo = () => import('../views/Blog/Info.vue');
const BlogHumor = () => import('../views/Blog/Humor.vue');

const ItInfrastructure = () => import('../views/ItInfrastructure/Index.vue');
const ItInfrastructureChildren = () => import('../views/ItInfrastructure/Children.vue');
const ItInfrastructureParent = () => import('../views/ItInfrastructure/Parent.vue');

const NetworkInfrastructure = () => import('../views/NetworkInfrastructure/Index.vue');
const NetworkInfrastructureChildren = () => import('../views/NetworkInfrastructure/Children.vue');
const NetworkInfrastructureParent = () => import('../views/NetworkInfrastructure/Parent.vue');

const Services = () => import('../views/Services/Index.vue');
const ServicesParent = () => import('../views/Services/Parent.vue');

const Search = () => import('../views/Search.vue');
const Contacts = () => import('../views/Contacts.vue');
const ErrorPage = () => import('../views/ErrorPage.vue');

const ClientsAndPartners = () => import('../views/ClientsAndPartners.vue');

const AboutCompany = () => import('../views/AboutCompany/Index.vue');
const AboutCompanyChildren = () => import('../views/AboutCompany/Children.vue');
const AboutCompanyParent = () => import('../views/AboutCompany/Parent.vue');

const InformationalSecurity = () => import('../views/InformationalSecurity/Index.vue');
const InformationalSecurityChildren = () => import('../views/InformationalSecurity/Children.vue');
const InformationalSecurityParent = () => import('../views/InformationalSecurity/Parent.vue');
const InformationalSecurityZahist = () => import('../views/InformationalSecurity/Zahist.vue');

const routes = [
  {
    path: '',
    name: 'Home',
    component: Home,
    meta: {
      // bodyClass: 'index',
      breadCrumb: 'Home Page'
    }
  },
  {
    path: '/paketnye-resheniya',
    name: 'BatchSolutions',
    component: BatchSolutions,
    meta: {
      bodyClass: 'BatchSolutions',
      breadCrumb: 'BatchSolutions Page'
    }
  },
  {
    path: '/ip-telefoniya',
    name: 'IpTelephony',
    component: IpTelephony,
    meta: {
      breadCrumb: 'IpTelephony Page'
    },
    children: [
      {
        path: '',
        name: 'IpTelephonyParent',
        component: IpTelephonyParent,
        meta: {
          breadCrumb: 'IpTelephonyParent Page'
        },
      },
      {
        path: ':children',
        name: 'IpTelephonyChildren',
        component: IpTelephonyChildren,
      },
    ]
  },
  {
    path: '/komplektuyuci',
    name: 'Set',
    component: Set,
    meta: {
      breadCrumb: 'Set Page'
    },
    children: [
      {
        path: '',
        name: 'SetParent',
        component: SetParent,
        meta: {
          breadCrumb: 'SetParent Page'
        },
      },
      {
        path: ':children',
        name: 'SetChildren',
        component: SetChildren,
      },
    ]
  },
  {
    path: '/blog',
    component: Blog,
    children: [
      {
        path: '',
        component: BlogIndex,
        name: 'Blog Parent',
        meta: {
          breadCrumb: 'Blog Parent'
        },
      },
      {
        path: ':name',
        component: BlogNews,
        name: 'BlogNews',
      },
      {
        path: 'info',
        component: BlogInfo,
        name: 'BlogInfo',
        meta: {
          breadCrumb: 'BlogInfo'
        },
      },
      {
        path: 'humor',
        component: BlogHumor,
        name: 'BlogHumor',
        meta: {
          breadCrumb: 'BlogHumor'
        },
      },
    ]
  },
  {
    path: '/it-infrastruktura',
    name: 'ItInfrastructure',
    component: ItInfrastructure,
    meta: {
      breadCrumb: 'ItInfrastructure Page'
    },
    children: [
      {
        path: '',
        name: 'ItInfrastructureParent',
        component: ItInfrastructureParent,
        meta: {
          breadCrumb: 'ItInfrastructureParent Page'
        },
      },
      {
        path: ':children',
        name: 'ItInfrastructureChildren',
        component: ItInfrastructureChildren,
        meta: {
          breadCrumb: 'ItInfrastructureChildren Page'

        },
      },
    ]
  },
  {
    path: '/merezeva-infrastruktura',
    name: 'NetworkInfrastructure',
    component: NetworkInfrastructure,
    meta: {
      breadCrumb: 'NetworkInfrastructure Page'
    },
    children: [
      {
        path: '',
        name: 'NetworkInfrastructure',
        component: NetworkInfrastructureParent,
        meta: {
          breadCrumb: 'NetworkInfrastructureParent Page'
        },
      },
      {
        path: ':children',
        name: 'NetworkInfrastructureChildren',
        component: NetworkInfrastructureChildren,
        meta: {
          breadCrumb: 'NetworkInfrastructureChildren Page'
        },
      },
    ]
  },
  {
    path: '/informaciina-bezpeka',
    name: 'InformationalSecurity',
    component: InformationalSecurity,
    meta: {
      breadCrumb: 'InformationalSecurity'
    },
    children: [
      {
        path: '',
        name: 'InformationalSecurityParent',
        component: InformationalSecurityParent,
        meta: {
          breadCrumb: 'InformationalSecurityParent Page'
        },
      },
      {
        path: ':children',
        name: 'InformationalSecurityZahist',
        component: InformationalSecurityZahist,
        children: [
          {
            path: ':children',
            name: 'InformationalSecurityyChildren',
            component: InformationalSecurityChildren,
          }
        ]
      },
    ]
  },
  {
    path: '/servisni-poslugi',
    name: 'Services',
    component: Services,
    meta: {
      breadCrumb: 'Services'
    },
    children: [
      {
        path: '',
        name: 'Services',
        component: ServicesParent,
        meta: {
          breadCrumb: 'ServicesParent Page'
        },
      },
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: Search,
    props: (route) => ({ query: route.query.query }),
    meta: {
      breadCrumb: 'Search'
    },
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: Contacts,
    meta: {
      breadCrumb: 'Contacts'
    },
  },
  {
    path: '/klienty-i-partnery',
    name: 'ClientsAndPartners',
    component: ClientsAndPartners,
    meta: {
      breadCrumb: 'ClientsAndPartners'
    },
  },
  {
    path: '/pro-kompaniyu',
    name: 'AboutCompany',
    component: AboutCompany,
    meta: {
      breadCrumb: 'AboutCompany'
    },
    children: [
      {
        path: '',
        name: 'AboutCompanyParent',
        component: AboutCompanyParent,
        meta: {
          breadCrumb: 'AboutCompanyParent Page'
        },
      },
      {
        path: ':name',
        name: 'AboutCompanyChildren',
        component: AboutCompanyChildren,
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'ErrorPage',
    component: ErrorPage,
    meta: {
      breadCrumb: '404'
    },
  },
]

const langRouterOptions = {
  defaultLanguage: 'uk',
  translations,
  // localizedURLs,
}

const routerOptions = {
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
  }
}

const router = createLangRouter(
  langRouterOptions,
  routerOptions
);

const vueBodyClass = new VueBodyClass(routes);
router.beforeEach((to, from, next) => { vueBodyClass.guard(to, next) })


export default router
