<template>
  <div>
    <metainfo>
      <template v-slot:title="{ content }"
        >{{ content }} - iIT Trading</template
      >
    </metainfo>
    <div class="wrapper" :class="getMainNavClasses" @create="showFormSearch">
      <Header
        :header="themeData"
        :translations="translations"
        :menu="headerMenu"
        :services="services"
        @openForm="showFormSearch"
      />
      <main>
        <router-view></router-view>
      </main>
      <Footer
        :footer="themeData"
        :translations="translations"
        :menu="footorMenu"
        @openForm="showFormSearch"
      />
      <transition name="showForm">
        <FormHide
          v-if="showForm"
          @click="showForm = false"
          :class="pos == 1 ? 'showFormHeader' : 'showFormFooter'"
        />
      </transition>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineAsyncComponent } from "vue";

const Header = defineAsyncComponent(() => import("@/components/Header.vue"));
const Footer = defineAsyncComponent(() => import("@/components/Footer.vue"));
const FormHide = defineAsyncComponent(() =>
  import("@/components/FormHide.vue")
);

import axios from "axios";
//Шина событий
import mitt from "mitt";
window.mitt = window.mitt || new mitt();

export default {
  name: "App",
  components: {
    Header,
    Footer,
    FormHide,
  },
  data() {
    return {
      locale: this.$i18n.locale,
      themeData: [],
      translations: [],
      services: [],
      headerMenu: [],
      footorMenu: [],
      scrolling: false,
      prevScrollpos: window.pageYOffset,
      showForm: false,
      pos: 0,
    };
  },
  metaInfo() {
    return {
      title: "App",
      meta: [{ name: "robots", content: "index,follow" }],
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    };
  },
  computed: {
    getMainNavClasses() {
      return {
        scrolling: this.scrolling,
      };
    },
  },

  methods: {
    async getThemeInfo() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API + "/api/" + this.locale + "/theme-info",
          config
        );
        this.themeData = response.data.data;
        this.services = response.data.data.services;
        this.headerMenu = response.data.data.menu;
        this.footorMenu = response.data.data.menu_link;
        this.translations = response.data.data.translations;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },

    scrollNow() {
      const currentScrollPos = window.pageYOffset;

      if (currentScrollPos == 0) {
        this.scrolling = false;
        return;
      } else {
        this.scrolling = true;
      }

      this.prevScrollpos = currentScrollPos;
    },

    handleScroll() {
      let doScoll;

      window.onscroll = () => {
        clearTimeout(doScoll);
        doScoll = setTimeout(this.scrollNow, 1);
      };
    },
    showFormSearch(position) {
      this.showForm = !this.showForm;

      if (position === "header") {
        this.pos = 1;
      } else if (position === "footer") {
        this.pos = 2;
      }
    },
  },
  mounted() {
    this.getThemeInfo();
  },
  created() {
    this.handleScroll();
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main";

//// анимация появления
.showForm-enter-from,
.showForm-leave-to {
  opacity: 0;
}

.showForm-enter-active .popup-container,
.showForm-leave-active .popup-container {
  opacity: 1;
}
</style>
